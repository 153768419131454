import Vue from 'vue';

// initial state
const state = () => ({ collection: [{ selected: false }] });

// getters
export const getters = {
  isSelected: (state) => (id) => {
    const index = state.findIndex((element) => element.id === id);
    if (index !== -1) {
      return state[index].selected;
    }
    return false;
  },
  numSelected(state) {
    let numSelected = 0;
    state.forEach((element) => {
      if (element.selected) {
        numSelected += 1;
      }
    });
    return numSelected;
  },
  isAllSelected(state) {
    let allSelected = true;
    if (!state.length) {
      return false;
    }
    state.forEach((element) => {
      if (!element.selected) {
        allSelected = false;
      }
    });
    return allSelected;
  },
  getSelected(state) {
    const selected = [];
    state.forEach((element) => {
      if (element.selected) {
        selected.push(element);
      }
    });
    return selected;
  },
};

// actions
export const actions = {
  setInitial(context) {
    context.dispatch('selectNone');
    context.dispatch('setAllVisible');
  },
  selectNone({ state }) {
    state.forEach((element) => {
      Vue.set(element, 'selected', false);
    });
  },
  selectAll({ state }) {
    state.forEach((element) => {
      Vue.set(element, 'selected', true);
    });
  },
  selectById(context, id) {
    context.commit('setItemSelected', id);
  },
  deselectById(context, id) {
    context.commit('setItemDeselected', id);
  },
  setAllVisible({ state }) {
    state.forEach((element) => {
      Vue.set(element, 'visible', true);
    });
  },
};

// mutations
export const mutations = {
  setCollection(state, collection) {
    state.collection = collection;
  },
  setItemSelected(state, id) {
    state.forEach((element) => {
      if (element.id === id) {
        element.selected = true;
      }
    });
  },
  setItemDeselected(state, id) {
    state.forEach((element) => {
      if (element.id === id) {
        element.selected = false;
      }
    });
  },
  filter(state, { value, fields }) {
    if (value === '') {
      state.forEach((element) => {
        element.visible = true;
      });
    } else {
      state.forEach((element) => {
        let hasMatched = false;
        fields.forEach((field) => {
          let val = element[field];
          if (field.includes('.')) {
            // If we need to grab a value inside of a dictionary, seperate keys with a dot
            const keys = field.split('.');
            val = element[keys[0]][keys[1]];
          }
          if (val && val.toLowerCase().indexOf(value.toLowerCase()) >= 0) {
            hasMatched = true;
          }
        });
        if (hasMatched) {
          element.visible = true;
        } else {
          element.selected = false;
          element.visible = false;
        }
      });
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
