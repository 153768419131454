import Vue from 'vue';
import collection from './Collection';

// initial state
const state = () => ({
  link: { acl: {}, owner: {} },
});

const modules = {
  collection,
};

// getters
export const getters = {
  getCollection(state) {
    return state.collection;
  },
};

// actions
const actions = {};

// mutations
export const mutations = {
  setLink(state, link) {
    state.link.downloadAllUrl = link.download_all;
    state.collection = link.files;
    Vue.set(state.link.acl, 'read', link.acl.read);
    Vue.set(state.link.acl, 'write', link.acl.write);
    Vue.set(state.link.acl, 'list', link.acl.list);
    Vue.set(state.link.owner, 'name', link.user.name);
    Vue.set(state.link.owner, 'email', link.user.email);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules,
};
