// initial state
const state = () => ({
  brand: undefined,
});

const STATIC_BACKGROUND_PREFIX = 'ftp/images';

// getters
export const getters = {
  getLoginBackground: (state) => {
    if (state.brand === undefined) {
      return [null, null];
    }
    if (state.brand.gridloginbgtype === 3) {
      return ['background-color', `#${state.brand.bgcolor}`];
    }
    if (state.brand.gridloginbgtype === 4) {
      return ['background-image', `url(${state.brand.gridloginbg})`];
    }
    const backgroundImage = state.brand.gridloginbg.replace(
      STATIC_BACKGROUND_PREFIX,
      '',
    );
    /* eslint-disable-next-line global-require, import/no-dynamic-require */
    const asset = require(`@/assets/backgrounds${backgroundImage}`);
    return ['background-image', `url(${asset})`];
  },
  getLogoURL: (state) => {
    if (state.brand !== undefined) {
      return state.brand.gridlogo;
    }
    return '';
  },
  getLogoStyle: (state) => {
    if (state.brand !== undefined) {
      return `height=${state.brand.gridlogoHeight};width=${state.brand.gridlogoWidth};`;
    }
    return '';
  },
  getIconURL: (state) => {
    if (state.brand !== undefined) {
      return state.brand.gridicon;
    }
    return '';
  },
  getBackgroundColor: (state, getters) => (type) => {
    if (state.brand !== undefined) {
      switch (type) {
        case 'main':
          return getters.getMainColor;
        case 'success':
          return getters.getSuccessColor;
        default:
          return getters.getHighlightColor;
      }
    }
    return '';
  },
  getMainColor: (state) => `#${state.brand.gridmaincolor}`,
  getSuccessColor: (state) => `#${state.brand.gridsuccesscolor}`,
  getHighlightColor: (state) => `#${state.brand.gridhighlightcolor}`,
  getCustomCss: (state) => state.brand && state.brand.gridcustom_css,
  getBrandName: (state) => {
    if (state.brand !== undefined) {
      return state.brand.name;
    }
    return '';
  },
};

// actions
const actions = {};

// mutations
export const mutations = {
  setBrand(state, brand) {
    state.brand = brand;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
