import Vue from 'vue';
import Vuex from 'vuex';
import BrandStore from './modules/BrandStore';
import LinkStore from './modules/LinkStore';
import PreferenceStore from './modules/Preferences';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    BrandStore,
    LinkStore,
    PreferenceStore,
  },
});
