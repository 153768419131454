<template>
  <div id="outer" data-testid="error404">
    <div id="number"><strong>404 Error</strong></div>
    <div id="inner">
      <h1>That's not good...</h1>
      <p>
        We couldn't find the page you were looking for. It may be temporarily
        unavailable, moved or no longer exist. Double check the URL you entered
        for any mistakes and try again. Sorry about that.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotFound',
  mounted() {
    document.body.style.backgroundColor = '#2c3e50';
  },
};
</script>

<style>
body,
html {
  margin: 3px 0px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
}
#outer {
  margin-top: 100px;
  background: #2c3e50;
}
#inner {
  padding: 20px;
  background: #ffffff;
  width: 500px;
  border-radius: 10px;
  margin: 0 auto;
}
p {
  line-height: 24px;
}
h1 {
  font-weight: bold;
  margin: 10px 0;
}
#number {
  font-size: 100px;
  line-height: 65px;
  margin: 0 auto;
  color: #fff;
  text-align: center;
  width: 500px;
}
</style>
