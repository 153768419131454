import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const isOnprem = process.env.NODE_ENV === 'development' ? 'true' : '$ON_PREM';

const notFoundRoute = {
  path: '/:pathMatch(.*)*',
  name: 'not-found',
  component: () => import(/* webpackChunkName: "notfound" */ '../views/NotFound.vue'),
};

const linkRoute = {
  name: 'Link',
  component: () => import(/* webpackChunkName: "linkhome" */ '../views/LinkHome.vue'),
};

const commonRoutes = [notFoundRoute];

const onpremRoutes = [
  {
    ...linkRoute,
    path: '/link/:uid([a-zA-Z0-9\\_\\-]+)/:path*',
  },
  ...commonRoutes,
];

const cloudRoutes = [
  {
    ...linkRoute,
    path: '/:uid([a-zA-Z0-9\\_\\-]+)/:path*',
  },
  ...commonRoutes,
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: isOnprem.toLowerCase() === 'true' ? onpremRoutes : cloudRoutes,
});

export default router;
