import Vue from 'vue';
import VueCookies from 'vue-cookies';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faFolderOpen,
  faFile,
  faFolder,
  faUpload,
  faDownload,
  faTimesCircle,
  faCheckSquare,
  faSort,
  faArrowDown,
  faArrowUp,
  faFilter,
  faTimes,
  faList,
  faTable,
  faTh,
  faKey,
  faEye,
  faPrint,
} from '@fortawesome/free-solid-svg-icons';
import {
  faArrowAltCircleDown,
  faSquare,
} from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';
import store from './stores';
import router from './router';
import App from './App.vue';

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

library.add(
  faFolderOpen,
  faFile,
  faFolder,
  faUpload,
  faDownload,
  faTimesCircle,
  faArrowAltCircleDown,
  faSquare,
  faCheckSquare,
  faSort,
  faArrowDown,
  faArrowUp,
  faFilter,
  faTimes,
  faList,
  faTable,
  faTh,
  faKey,
  faEye,
  faPrint,
);

const dsn = process.env.NODE_ENV === 'development'
  ? process.env.VUE_APP_SENTRY_DSN
  : '$VUE_APP_SENTRY_DSN';

const release = process.env.NODE_ENV === 'development'
  ? '1.0.0'
  : '$SENTRY_RELEASE';

Sentry.init({
  Vue,
  dsn,
  release,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 0.1,
  logErrors: process.env.NODE_ENV !== 'production',
});

Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.config.productionTip = false;
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueCookies);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
