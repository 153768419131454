<template>
  <div id="app" :style="cssProps">
    <NotFound v-if="notfound" />
    <router-view v-else @onerror="onerror" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import NotFound from '@/views/NotFound.vue';

export default {
  components: {
    NotFound,
  },
  data() {
    return {
      notfound: false,
    };
  },
  computed: {
    ...mapGetters({
      getIconURL: 'BrandStore/getIconURL',
      getBackgroundColor: 'BrandStore/getBackgroundColor',
      getBrandName: 'BrandStore/getBrandName',
      getCustomCss: 'BrandStore/getCustomCss',
    }),
    cssProps() {
      return {
        '--main-color': this.getBackgroundColor('main'),
        '--success-color': this.getBackgroundColor('success'),
        '--highlight-color': this.getBackgroundColor('highlight'),
      };
    },
  },
  watch: {
    getBrandName(newname) {
      const link = document.querySelector("link[rel*='icon']")
        || document.createElement('link');
      link.type = 'image/x-icon';
      link.rel = 'shortcut icon';
      link.href = this.getIconURL;
      document.getElementsByTagName('head')[0].appendChild(link);
      document.title = newname;
    },
    getCustomCss(css) {
      let style = document.getElementById('brandcss');
      if (!style) {
        style = document.createElement('style');
        style.id = 'brandcss';
      }
      style.textContent = css;
      const body = document.getElementById('container');
      body.appendChild(style);
    },
  },
  methods: {
    onerror() {
      this.notfound = true;
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#button-toolbar {
  background: #eee;
  padding: 10px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
.modal-header {
  border-bottom: none !important;
}
.modal-header h5 {
  flex-grow: 4;
  text-align: center;
}
.highlight-color {
  background-color: var(--highlight-color) !important;
}
.success-color {
  background-color: var(--success-color) !important;
}
.main-color {
  background-color: var(--main-color) !important;
}
.main-text {
  color: var(--main-color) !important;
}
</style>
