// initial state
const state = () => ({
  preferences: { sort: 'name', view: 'list' },
});

// getters
export const getters = {
  getPreference: (state) => (preference) => state.preferences[preference],
};

// actions
const actions = {};

// mutations
export const mutations = {
  setSort(state, val) {
    state.preferences.sort = state.preferences.sort === val ? `-${val}` : val;
  },
  setView(state, val) {
    state.preferences.view = val;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
